import { useCallback, useReducer, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import last from 'lodash/last';
import flatten from 'lodash/flatten';

import { BffApiFetchItemsError } from '../../../../../../types';

import {
  BffInfiniteIndexQueryResponse,
  BffInfiniteIndexQueryDefaultOptionsOpts,
  BffInfiniteIndexQueryData,
  BffInfiniteIndexQueryBaseNodeType
} from './useBffInfiniteIndexQuery.types';

import {
  FetchItemsUrl,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemCacheKey,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsSerializer
} from '../../../../../../types';

import { useUpdateInfiniteIndexQueryItemCache } from '../useInfiniteIndexQuery/hooks/useUpdateInfiniteIndexQueryItemCache';

import { changeItemsFiltersAction } from '../useInfiniteIndexQuery/actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from '../useInfiniteIndexQuery/actions/clearItemsFiltersAction';
import { filterItemsAction } from '../useInfiniteIndexQuery/actions/filterItemsAction';
import { sortItemsAction } from '../useInfiniteIndexQuery/actions/sortItemsAction';
import { limitItemsAction } from '../useInfiniteIndexQuery/actions/limitItemsAction';

import {
  indexRequestReducer,
  IndexRequestReducerType
} from '../useInfiniteIndexQuery/reducers/indexRequestReducer';
import { BffApiRequest } from '../../../../../../utils/BffApiRequest';
import { parseRequestError } from '../../../../../../utils/parseRequestError';
import { retryCustomFunction } from '../useInfiniteIndexQuery/utils/retryCustomFunction';
import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  INITIAL_FILTERS,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT
} from '../useIndexQuery/indexRequestConstants';

type BffInfiniteIndexQueryErrorType = BffApiFetchItemsError;

interface BffInfiniteIndexQueryDefaultOptions<NodeType, MetaType = unknown> {
  cacheKey: FetchItemsCacheKey;
  url: FetchItemsUrl;
  scope: string;
  initialFilters?: FetchItemsFilters;
  initialSort?: FetchItemsSort;
  initialLimit?: FetchItemsLimit;
  serializer?: FetchItemsSerializer;
  options?: BffInfiniteIndexQueryDefaultOptionsOpts<NodeType, MetaType>;
}

interface BffInfiniteIndexQueryWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemGqlQuery;
}

interface BffInfiniteIndexQueryWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

interface BffInfiniteIndexQueryParams {
  filters: FetchItemsFilters;
  sort: FetchItemsSort;
  limit: FetchItemsLimit;
  serializer?: FetchItemsSerializer;
}

type BffInfiniteIndexQueryOptions<
  NodeType,
  MetaType = unknown
> = BffInfiniteIndexQueryDefaultOptions<NodeType, MetaType> &
  (
    | BffInfiniteIndexQueryWithPrefetchItemOptions
    | BffInfiniteIndexQueryWithoutPrefetchItemOptions
  );

function useBffInfiniteIndexQuery<
  NodeType extends BffInfiniteIndexQueryBaseNodeType,
  MetaType = unknown
>({
  cacheKey,
  scope,
  url,
  initialFilters = INITIAL_FILTERS,
  initialSort = INITIAL_SORT,
  initialLimit = INITIAL_LIMIT,
  serializer,
  // fetchItemCacheKey,
  // fetchItemQuery,
  options = {}
}: BffInfiniteIndexQueryOptions<NodeType, MetaType>) {
  const localForageCacheKey = `${cacheKey}-infinite-index`;

  const [{ currentFilters, currentLimit, currentSort }, dispatch] =
    useReducer<IndexRequestReducerType>(indexRequestReducer, {
      currentLimit: initialLimit,
      currentFilters: initialFilters,
      currentSort: initialSort
    });

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useInfiniteQuery<BffInfiniteIndexQueryResponse<NodeType, MetaType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<BffInfiniteIndexQueryResponse<NodeType, MetaType>>(
          localForageCacheKey
        ),
      { enabled: options.enabledPlaceholder }
    );

  const currentParams = useMemo<BffInfiniteIndexQueryParams>(
    () => ({
      filters: currentFilters,
      sort: currentSort,
      limit: currentLimit,
      serializer
    }),
    [currentFilters, currentSort, currentLimit, serializer]
  );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, currentParams];
  }, [cacheKey, currentParams]);

  const handleQuery = useCallback<
    (params: any) => Promise<BffInfiniteIndexQueryResponse<NodeType, MetaType>>
  >(
    async ({ pageParam = INITIAL_PAGE }) => {
      try {
        const response = await BffApiRequest.get<
          BffInfiniteIndexQueryResponse<NodeType, MetaType>
        >(url, {
          page: pageParam,
          ...currentParams
        });

        return response.data;
      } catch (err) {
        throw isEmpty(err?.response?.data?.error?.fullMessages)
          ? err
          : err?.response?.data?.error;
      }
    },
    [currentParams, url]
  );

  const handleCurrentQuery = useCallback<
    (params: any) => Promise<BffInfiniteIndexQueryResponse<NodeType, MetaType>>
  >(
    (params) => handleQuery({ ...currentParams, ...params }),
    [currentParams, handleQuery]
  );

  const {
    data,
    isFetched,
    isLoading,
    error,
    isPlaceholderData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery<
    BffInfiniteIndexQueryResponse<NodeType, MetaType>,
    BffInfiniteIndexQueryErrorType
  >(fullCacheKey, handleCurrentQuery, {
    enabled: options.enabled || placeholderDataFetched,
    cacheTime: options.cacheTime,
    staleTime: options.staleTime,
    keepPreviousData: options.keepPreviousData,
    retry: retryCustomFunction,
    onSuccess: useCallback(
      (data) => {
        options.onSuccess?.(data);
        if (
          data?.pages[0] &&
          size(data?.pages) === 1 &&
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentLimit === initialLimit
        ) {
          return LocalForage.setItem<
            BffInfiniteIndexQueryResponse<NodeType, MetaType>
          >(localForageCacheKey, data.pages[0]);
        }
      },
      [
        currentFilters,
        currentSort,
        currentLimit,
        initialFilters,
        initialSort,
        initialLimit,
        localForageCacheKey,
        options
      ]
    ),
    placeholderData: useCallback(() => {
      if (
        placeholderData?.pages?.[0] &&
        isEqual(currentFilters, initialFilters) &&
        isEqual(currentSort, initialSort) &&
        currentLimit === initialLimit
      ) {
        return placeholderData as BffInfiniteIndexQueryData<NodeType, MetaType>;
      }
    }, [
      currentFilters,
      currentSort,
      currentLimit,
      initialFilters,
      initialSort,
      initialLimit,
      placeholderData
    ]),
    getNextPageParam: useCallback(
      (lastPage) => {
        return lastPage?.[scope]?.paginationInfo?.nextPage ?? undefined;
      },
      [scope]
    )
  });

  const loadMoreItems = useCallback(() => fetchNextPage(), [fetchNextPage]);

  const lastQueryResponseValue = last(data?.pages)?.[scope];
  const placeholderResponseValue = placeholderData?.pages?.[0]?.[scope];

  const updateItemCache = useUpdateInfiniteIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const items = useMemo<NodeType[]>(() => {
    const pagesNodes = data?.pages?.map((page) => page?.[scope]?.nodes);
    return pagesNodes ? flatten(pagesNodes) : [];
  }, [data, scope]);

  const isLoadingTotalCount = isLoading
    ? placeholderResponseValue?.paginationInfo?.totalCount
    : null;

  const queryClient = useQueryClient();

  const meta = last(data?.pages)?.[scope]?.meta || <MetaType>{};

  return {
    data,
    items,
    itemsError: parseRequestError(error),
    itemsErrorMessage: parseRequestError(error),
    itemsTotalCount:
      lastQueryResponseValue?.paginationInfo?.totalCount ||
      isLoadingTotalCount ||
      0,
    meta,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentFilters,
    currentSort,
    currentPage: lastQueryResponseValue?.paginationInfo?.currentPage,
    currentLimit,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems: useCallback(
      (nextFilters: FetchItemsFilters) =>
        dispatch(filterItemsAction(nextFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback(
      (
        changedFilters: Partial<FetchItemsFilters>,
        removeFilters: string[] = []
      ) => dispatch(changeItemsFiltersAction(changedFilters, removeFilters)),
      [dispatch]
    ),
    clearItemsFilters: useCallback(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    sortItems: useCallback(
      (nextSort: FetchItemsSort) => dispatch(sortItemsAction(nextSort)),
      [dispatch]
    ),
    limitItems: useCallback(
      (nextLimit: FetchItemsLimit) => dispatch(limitItemsAction(nextLimit)),
      [dispatch]
    ),
    prefetchItems: useCallback(
      ({
        nextFilters,
        nextSort,
        nextPage,
        nextLimit
      }: {
        nextFilters?: FetchItemsFilters;
        nextSort?: FetchItemsSort;
        nextPage?: FetchItemsPage;
        nextLimit?: FetchItemsLimit;
      }) => {
        const params = {
          filters: nextFilters || currentFilters,
          sort: nextSort || currentSort,
          page: nextPage,
          limit: nextLimit || currentLimit,
          serializer
        };
        return queryClient.prefetchQuery([cacheKey, params], () => {
          return handleQuery(params);
        });
      },
      [
        queryClient,
        cacheKey,
        currentFilters,
        currentSort,
        currentLimit,
        serializer,
        handleQuery
      ]
    )
  };
}

export default useBffInfiniteIndexQuery;
